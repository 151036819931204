<template>
    <transition name="modal" mode="out-in" >
        <div v-show="show" class="fixed top-5 z-50 right-5">
            <transition name="slide">
                <div v-show="show" class="bg-gray-900 rounded-md p-3 relative">
                    <div v-if="status == 'success'" class="flex items-center">
                        <div class="rounded-full bg-green-500 h-9 w-9 ml-2 mr-5 flex items-center justify-center">
                            <i class="icon-checkmark2 text-green-100"></i>
                        </div>
                        <div class="w-64">
                            <div class="text-green-500 font-semibold">Success</div>
                            <p class="text-gray-400">{{message}}</p>
                        </div>
                    </div>
                    <div v-if="status == 'warning'" class="flex items-center">
                        <div class="rounded-full bg-yellow-500 h-9 w-9 ml-2 mr-5 flex items-center justify-center">
                            <i class="icon-warning text-green-100"></i>
                        </div>
                        <div class="w-64">
                            <div class="text-yellow-500 font-semibold">Warning</div>
                            <p class="text-gray-400">{{message}}</p>
                        </div>
                    </div>
                    <div v-if="status == 'info'" class="flex items-center">
                        <div class="rounded-full bg-cyan-500 h-9 w-9 ml-2 mr-5 flex items-center justify-center">
                            <i class="icon-info3 text-cyan-100"></i>
                        </div>
                        <div class="w-64">
                            <div class="text-cyan-500 font-semibold">Info</div>
                            <p class="text-gray-400">{{message}}</p>
                        </div>
                    </div>
                    <div v-if="status == 'error'" class="flex items-center">
                        <div class="rounded-full bg-red-500 h-9 w-9 ml-2 mr-5 flex items-center justify-center">
                            <i class="icon-cross3 text-red-100"></i>
                        </div>
                        <div class="w-64">
                            <div class="text-red-500 font-semibold">Error</div>
                            <p class="text-gray-400">{{message}}</p>
                        </div>
                    </div>
                    <div class="absolute top-1 right-1">
                        <button @click="close" class="h-7 w-7 text-red-500"><i class="icon-cross3 icon-1x"></i></button>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    data () {
        return {
            show: false,
            status: '',
            message: '',
            myTimeout: '',
        }
    },
    methods: {
        open (status, message) {
            this.show = true
            this.status = status
            this.message = message
            
        },
        close () {
            this.show = false
            clearTimeout(this.myTimeout)
        },
        
    },
    watch: {
        show (val) {
            if(val) {
               this.myTimeout = setTimeout(this.close, 3500)
            }
        }
    }
}
</script>